//JQuery
import $ from 'jquery';

global.jQuery = $;
global.jquery = $;
global.$ = $;
global.window.jQuery = $;
global.window.jquery = $;
global.window.$ = $;

import '../../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js'

import '../../node_modules/admin-lte/dist/js/adminlte';


// $(document).ready(function () {
//     setTimeout(()=>{
//         $('[data-toggle="tooltip"]').tooltip();
//
//     },1600)
// });
// $(document).ready(function() {
//     $("body").tooltip({ selector: '[data-toggle=tooltip]' });
// });
